import BaseAPI from "./BaseAPI";

import { Hook } from "../Utils/Hook";

export default class Account extends BaseAPI {
    constructor() {
        super();
        this.onAccountUpdate = new Hook();
    }

    async fetchAccount() {
        let fields = this.fields;
        fields['loading'] = true;
        return await this.getJSON('identity').then(data => {
            let resp = data.data;
            fields['account'] = resp.data;
            this.onAccountUpdate.invoke(resp.data);
            return resp.data;
        }).finally(() => {
            fields['loading'] = false;
        });
    }

    async performLogout() {
        return await this.getJSON('logout').then(data => {
            this.fields['account'] = {};
            this.onAccountUpdate.invoke({});
            return data.data.data;
        });
    }

    reloadAccount() {
        this.fields['account'] = {};
        this.fetchAccount();
    }

    getAccount() {
        if (!this.fields['account'] && !this.fields['loading']) {
            this.fields['account'] = {};
            this.fetchAccount();
        }
        return this.fields['account'];
    }

    name() {
        return this.getAccount()?.user?.name;
    }

    isAuthorized() {
        return this.getAccount()?.authorized;
    }
}