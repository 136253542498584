import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios';

import './App.css';

import Popup from './Coms/Popup';
import NavBar from './Coms/NavBar';

import Footer from "./Coms/Footer"
import Dropdown from './Coms/Dropdown/Dropdown';
import { createDropdown as dropdownShortcutMaker } from './Coms/Dropdown/DropdownShortcut';

import Login from './Auth/Login/Login';
import Register from './Auth/Register/Register';
import ResetPassword from './Auth/ResetPassword/ResetPassword';
import ForgotPassword from './Auth/ForgotPassword/ForgotPassword';

function App({ savedState, langs, globData, user, render }) {
  const searchParams = new URLSearchParams(document.location.search);
  const [cookies, setCookie] = useCookies(['__uapc_lang', '__uapc_token']);

  savedState.nonPersistent = {};
  const [state, setState] = useState(savedState);

  const [dropdowns, setDropdowns] = useState([]);

  globData.partList = ["case", "power_supply", "motherboard", "cpu", "cpu_cooler", "gpu", "ram", "storage"];

  globData.query = searchParams;
  globData.state = state;
  globData.setState = setState;
  globData.state.nonPersistent.dropdown = globData.originDropdown;
  globData.showPopup = (kind) => globData.setState((oldState) => {
    let state = { ...oldState };
    state.nonPersistent.popup_type = kind;
    return state;
  });
  globData.closePopup = () => globData.showPopup();
  globData.toggleTheme = () => setState((newState) => {
    return ({ ...newState, theme: newState.theme === "bright" ? "dark" : "bright" });
  });
  globData.setLanguage = (lang) => {
    axios.get("https://uapc.store/fixed/lang/" + (lang || "ua") + ".json")
      .then(data => {
        globData.langs = data.data;
        setState((newState) => ({ ...newState, lang }));
        setCookie('__uapc_lang', state.lang, { path: '/', domain: "uapc.store" });
        globData.language = lang;
        globData.translate = (key, langs) => {
          let raw = data.data[key] || key;
          return langs ? raw.format(langs) : raw;
        };
      });
  }
  globData.createDropdown = (color, text) => {
    setDropdowns(old => {
      const n = [...old];
      const uuid = new Date().getTime() + "";
      const dd = dropdownShortcutMaker(color, text);
      n.push([uuid, <Dropdown key={uuid} globData={globData} params={dd} />]);
      setTimeout(() => setDropdowns(old => old.filter(key => key[0] !== uuid)), 11000);
      return n;
    });
  };

  useEffect(() => {
    let statePersistent = { ...state };
    statePersistent["nonPersistent"] = undefined;
    localStorage.setItem("state", JSON.stringify(statePersistent));
    if (state.lang) setCookie('__uapc_lang', state.lang, { path: '/', domain: "uapc.store" });
    if (state.token) {
      let expires = new Date();
      expires.setTime(parseInt(state.token.split(".")[2], 36) + 2592000000);
      setCookie('__uapc_token', state.token, { path: '/', expires, secure: true, domain: "uapc.store" });
    }
  }, [state, setCookie]);

  return (
    <>
      <div className="App" theme={state.theme} lang={cookies.__uapc_lang}>
        <NavBar langs={langs} globData={globData} user={user} />
        {render()}
        <Footer globData={globData} />
      </div>

      <Popup globData={globData} trigger={globData.state.nonPersistent?.popup_type === "login"} backgroundColor={globData.state.theme === "bright" ? "#D9D9D9" : "#222222"}>
        <Login globData={globData} />
      </Popup>

      <Popup globData={globData} trigger={globData.state.nonPersistent?.popup_type === "register"} backgroundColor={globData.state.theme === "bright" ? "#D9D9D9" : "#222222"}>
        <Register globData={globData} />
      </Popup>

      <Popup globData={globData} trigger={globData.state.nonPersistent?.popup_type === "forgot_password"} backgroundColor={globData.state.theme === "bright" ? "#D9D9D9" : "#222222"}>
        <ForgotPassword globData={globData} />
      </Popup>

      <Popup globData={globData} trigger={searchParams.has("reset_password") && !globData.state.nonPersistent?.reset_password_closed} backgroundColor={globData.state.theme === "bright" ? "#D9D9D9" : "#222222"}>
        <ResetPassword globData={globData} token={searchParams.get("reset_password")} />
      </Popup>

      {searchParams.get("show_popup") ? (<Dropdown globData={globData} params={searchParams} />) : (<></>)}
      {dropdowns.map(key => key[1])}
    </>
  );
}

export default App;
