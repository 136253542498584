import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

import "./ResetPassword.css";
import ChangePassword from "../../Api/ChangePassword";

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const passRegex = /(?=.*?([a-z]|[A-Z]))(?=.*?[0-9]).{8,}$/;

const PasswordAPI = new ChangePassword();

export default function ResetPassword({ globData, token }) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [canLogin, setCanLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const newPwdRef = useRef();
    const confirmPwdRef = useRef();
    const resetPwdRef = useRef();

    const lockButton = () => {
        let newPass = newPwdRef.current.value;
        let newPassConfirm = confirmPwdRef.current.value;

        setCanLogin(newPass === newPassConfirm && passRegex.test(newPass));
    };

    const closeReset = () => {
        executeRecaptcha('invalidate_reset_pwd')
            .then((captcha) => {
                PasswordAPI.cancelPasswordReset(captcha, token)
                    .finally(() => {
                        navigate("/");
                        globData.setState((state) => ({ ...state, nonPersistent: { ...state.nonPersistent, reset_password_closed: true } }));
                    })
                    .catch((e) => {
                      globData.createDropdown("#FF907F", globData.translate("unexpected_error"));
                    });
            })
            .catch((reason) => {
                console.error("Re-Captcha rejected with reason: " + reason);
                globData.createDropdown("#FF907F", globData.translate("error_captcha_failed_request"));
                setCanLogin(true);
                setIsLoading(false);
                navigate("/");
            });
    };

    const performReset = () => {
        executeRecaptcha('perform_reset_pwd')
            .then((captcha) => {
                PasswordAPI.performPasswordReset(captcha, token, base64_encode(newPwdRef.current.value))
                    .then((resp) => {
                        if(resp.ok) {
                            globData.createDropdown("#69B75B", globData.translate("password_reset_complete"));
                        }
                    })
                    .catch((e) => {
                      globData.createDropdown("#FF907F", globData.translate("unexpected_error"));
                    })
                    .finally(() => {
                        navigate("/");
                        globData.setState((state) => ({ ...state, nonPersistent: { ...state.nonPersistent, reset_password_closed: true } }));
                    });
            })
            .catch((reason) => {
                console.error("Re-Captcha rejected with reason: " + reason);
                globData.createDropdown("#FF907F", globData.translate("error_captcha_failed_request"));
                setCanLogin(true);
                setIsLoading(false);
                navigate("/");
            });
    };

    return (
        <div className='reset-password-form normal-text-color'>
            <section style={{ "justifyContent": "center", "display": "flex" }}>
                <div className="normal-text-color reset-form-title">
                    <span>{globData.translate("reset_password_title")}</span>
                </div>
            </section>

            <div className='separator' />

            <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
                <span className="normal-text-color vert-center-lbl">{globData.translate("register_password")}</span>
                <input onChange={lockButton} ref={newPwdRef} className="input-text-field" type={"password"} />
            </div>

            <div style={{ width: "100%", height: "20px" }} />

            <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
                <span className="normal-text-color vert-center-lbl">{globData.translate("register_confirm_password")}</span>
                <input onChange={lockButton} ref={confirmPwdRef} className="input-text-field" type={"password"} />
            </div>

            <div className='separator' />

            <section>
                <div className="login-buttons">
                    <div className='switch-2-register-space'>
                        <button className='default-btn switch-to-register' onClick={closeReset}>{globData.translate("close")}</button>
                    </div>
                    <div className='perform-login-space'>
                        <button className='perform-login default-btn' loading={isLoading + ""} onClick={performReset} disabled={!canLogin} ref={resetPwdRef}>{globData.translate("reset_password_btn")}</button>
                    </div>
                </div>
            </section>
        </div>
    )
}