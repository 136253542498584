import React, { Component } from 'react'

import { Link } from "react-router-dom";
import UserProfileDropdown from './UserDropdown/UserProfileDropdown';

export class NavBar extends Component {
  constructor(props) {
    super(props);

    this.accountHook = () => { };
    this.state = {
      showDD: false,
      moav: false,
      monv: false,
      modd: false,
      user: props.user,
      authorized: props.user.authorized
    };
  }

  componentDidMount() {
    this.accountHook = this.props.globData.classes.Account.onAccountUpdate.addListener((acc) => {
      this.setState((old) => { return { ...old, user: acc, authorized: acc.authorized } });
      console.log("Account switch detected.");
    });
  }

  componentWillUnmount() {
    this.accountHook();
    this.accountHook = () => { };
  }

  shouldComponentUpdate(_nextProps, nextState, _nextContext) {
    if (!nextState.moav && !nextState.monv && !nextState.modd) {
      const setShowDD = (v) => this.setState({ ...nextState, showDD: v });
      if (nextState.showDD) {
        setShowDD(false);
      }
    }
    return true;
  }

  render() {
    const { langs, globData } = this.props;

    const state = this.state;

    const setState = (v) => this.setState(v);
    const setMOAV = (v) => this.setState((state) => ({ ...state, moav: v }));
    const setMONV = (v) => this.setState((state) => ({ ...state, monv: v }));
    const setMODD = (v) => this.setState((state) => ({ ...state, modd: v }));

    function startHoveringOnLogin(_e) {
      setState((state) => { return { ...state, moav: true, showDD: true } });
    }

    return (
      <>
        <UserProfileDropdown authorized={state.authorized} visible={state.showDD} langs={langs} globData={globData} user={state.user} setMODD={setMODD} />
        <div className="navbar" onMouseEnter={() => setMONV(true)} onMouseLeave={() => setMONV(false)}>
          <Link to="/" className="navbar-logo">
            <img src={"/static/media/full_logo_" + globData.state.theme + ".svg"} style={{
              "paddingLeft": "3px",
              "paddingRight": "7px",
              "paddingTop": "3px"
            }} alt="" />
          </Link>

          <img className="navbar-login navbar-profile"
            onMouseEnter={startHoveringOnLogin}
            onMouseLeave={() => setMOAV(false)}
            src={state.authorized ?
              (state.user.user.avatar || "https://uapc.store/fixed/photo/no_avatar.png") :
              /*user is NOT logged in*/ "https://uapc.store/fixed/photo/no_user.png"}
          />
        </div>
        <div className="navbar-h"/>
      </>
    )
  }
}

export default NavBar