import React from 'react'
import { Link } from "react-router-dom";

function FeaturedProduct({ build, globData }) {
    if (build.hide) return (<></>);

    let noStockColor = build.price_override?.color;
    if (!noStockColor) noStockColor = "#c9123d";

    let noStockText = build.price_override?.text;
    if (!noStockText) noStockText = "out_of_stock";
    if (globData.langs[noStockText]) noStockText = globData.langs[noStockText];

    return (
        <Link to={"/prebuilds/" + build.id} instock={"" + build.show_price} className="pc-build-overview">
            <div className="pc-build-preview-image-ctr">
                <img
                    instock={"" + build.show_price}
                    src={build.image || "/static/media/no_image.svg"}
                    alt="Build Preview"
                    className="pc-build-preview-image-img" />
            </div>
            <div className="pc-build-preview-labels">
                <div className="pc-build-title-name">
                    <p className="normal-text-color pc-build-preview-title" >{build.title[globData.language]}</p>
                </div>
                <div className="pc-build-price-label">
                    <p
                        className={"pc-build-price-" + (build.show_price ? "in-stock" : "no-stock")}
                        style={!build.show_price ? { color: noStockColor, opacity: 1 } : {}}
                    >{!build.show_price ? noStockText : build.price + " ₴"}</p>
                </div>
            </div>
        </Link>
    )
}

export default function FeaturedProductList({ globData }) {
    return (
        <div className="horizontal-flex-list">
            {globData.ftBuilds.map((build, index) => (<FeaturedProduct key={index} globData={globData} build={build} />))}
        </div>
    )
}
