import React from 'react'

import CSS from './Dropdown.css';

export default function Dropdown({ globData, params }) {
  return (
    <div hidden={(!params.get("show_popup"))} className="dropdown-notification" style={{ backgroundColor: params.get("popup_color") || "transparent" }}>
      {params.get("popup_text")}
    </div>
  )
}