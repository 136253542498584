import React, { useEffect, useState } from 'react';

import FeaturedProductList from '../../Coms/FeaturedProductList';

import CSS from './MainPage.css';

function App({ globData }) {
    const { translate } = globData;

    let reviews = [{
        avatar: undefined,
        displayName: "Review Nickname",
        computer: "<PC BUILD CONTENT>",
        review: "Review message",
        "time": "2022-12-04T22:53:50.767178700Z"
    }];

    const [activeReview, setActiveReview] = useState(-8);

    return (
        <>
            <section className="main-info">
                <div className="main-photo"></div>
                <div className='height-7-5'></div>
                <div className="main-about-us">
                    <div className="main-about-us-header normal-text-color">
                        {translate('about_us_header')}
                    </div>
                    <div className="main-about-us-text normal-text-color">
                        {translate("about_us_full")}
                    </div>
                </div>
                <div className='height-7-5'></div>
            </section>

            <section className="main-products">
                <div className="main-products-header normal-text-color">{translate("prebuilt_pcs")}</div>
                <FeaturedProductList globData={globData} />
            </section>

            <section className="main-commission-build">
                <div className='normal-text-color etext'>{translate("call_to_custom_build")}</div>
                <div className='comm'>
                    <button className='default-btn' style={{ padding: "16px 32px 16px 32px", fontSize: "32px" }}>{translate("main_comm_build_btn")}</button>
                </div>
            </section>

            {reviews.length > 0 ?
                <section className="main-recent-reviews" style={{ overflow: "hidden" }}>
                    <div className='reviews-left-portal' />
                    <div className='reviews-right-portal' />

                    {reviews.map((review, index) => <div key={index} className='review' hidden={activeReview !== index} style={{ position: (activeReview === index ? "inherit" : "absolute"), zIndex: (activeReview === index ? 1 : 0), transform: "translateX(" + ((index - activeReview) * 120) + "vw)" }}>
                        <div className='review-left'>
                            <div className='review-top'>
                                <div className='review-avatar'>
                                    <img className="review-avatar-icon" src={review.avatar || "https://uapc.store/fixed/photo/no_avatar.png"} />
                                    <span className='normal-text-color'>{review.displayName}</span>
                                </div>
                            </div>
                            <span>{review.computer}</span>
                        </div>
                        <div className='review-right'>
                            <span>{review.review}</span>
                            <br /><br />
                            <div className='review-date'>
                                <span>{new Date(review.time).toLocaleDateString()}</span>
                            </div>
                        </div>
                    </div>)}
                </section> : <></>}
        </>
    );
}

export default App;
