import React from 'react'

import Obfuscate from 'react-obfuscate'

import CSS from './Footer.css';

export default function Footer({ globData }) {
  return (<div className="footer" >
    <div className="footer-left">
      <div>
        <Obfuscate className="link-color" email="support@uapc.store" target="_blank" />
      </div>
      <div>
        <Obfuscate className="link-color" tel="+38(050)259-03-69" /><span> </span>
        <font color={globData.state.theme === "bright" ? "#AAAAAA" : "#888888"} size="2">
          {globData.translate("call_hours", ["15:00", "22:00"])}
        </font>
      </div>
      <div style={{ "padding": "0px 0px 12px 0px" }}>
        © 2022 <font color="#6a86c4">U</font><font color="#f9b016">A</font>PC Store. {globData.translate("all_rights_reserved")}
      </div>
    </div>

    <div className="footer-right">
      <div>
        <img src='https://uapc.store/fixed/png/telegram-app.png' height="18" style={{ "verticalAlign": "bottom", "marginRight": "4px" }} />
        Telegram:<span> </span>
        <a className="link-color" href="https://t.me/ua_pc" target="_blank" rel="noreferrer">
          {globData.translate("telegram_channel")}
        </a><span> | </span>
        <a className="link-color" href="https://t.me/UAPCBot" target="_blank" rel="noreferrer">
          {globData.translate("telegram_bot")}
        </a><span> | </span>
        <a className="link-color" href="https://t.me/UAPC_Store" target="_blank" rel="noreferrer">
          {globData.translate("telegram_support")}
        </a>
      </div>

      <div>
        <img src='https://uapc.store/fixed/png/instagram-app.png' height="18" style={{ "verticalAlign": "bottom", "marginRight": "4px" }} />
        Instagram:<span> </span>
        <a className="link-color" href="https://www.instagram.com/uapc_store/" target="_blank" rel="noreferrer">
          uapc_store
        </a>
      </div>

      <div style={{ "padding": "0px 0px 12px 0px" }}>
        <img src='https://uapc.store/fixed/png/viber-app.png' height="18" style={{ "verticalAlign": "bottom", "marginRight": "4px" }} />
        Viber:<span> </span>
        <a className="link-color" href="https://invite.viber.com/?g2=AQBZZ1zEaLAkoFA3Xq%2F0%2BiIwXYHzvftV1NaYPHG7jJBO2t5UqF1cDWLEff9iBFQS" target="_blank" rel="noreferrer">
          {globData.translate("telegram_channel")}
        </a>
      </div>
    </div>
  </div>)
}