
export class Hook {
    constructor() {
        this.callers = [];
    }

    addListener(f) {
        this.callers.push(f);
        return () => this.callers = this.callers.filter((e) => e !== f);
    }

    invoke(thing) {
        this.callers.forEach(hook => {
            hook(thing);
        });
    }
}