import React, { useRef } from 'react'
import ReactDom from 'react-dom';

import CSS from './Popup.css';

function Popup({ globData, children, trigger, backgroundColor }) {

  function closePopup() {
    let state = { ...globData.state };
    state.nonPersistent.popup_type = undefined;
    globData.setState(state);
  }

  const innerRef = useRef();

  return trigger ? (
    ReactDom.createPortal(
      <div className="popup App" theme={globData.state.theme}>
        <div ref={innerRef} className="popup-inner" style={{ "backgroundColor": (backgroundColor || (globData.state.theme === "bright" ? "#FFF" : "#222")) }}>
          <button className="close" cursor="default" invisible="true" onClick={closePopup}></button>
          {children}
        </div>
      </div>,
      document.getElementById("popup")
    )
  ) : "";
}

export default Popup