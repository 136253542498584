import React from 'react'
import { useNavigate } from 'react-router-dom';

import CSS from './UserProfileDropdown.css';

export default function UserProfileDropdown({ globData, visible, user, setMODD, authorized }) {
  const { translate } = globData;
  const navigate = useNavigate();

  // visible = true; // FIXME!!!!

  const logout = (e) => {
    e.preventDefault();

    if (authorized) {
      globData.classes.Account.performLogout().finally(() => {
        globData.createDropdown("#A2B55A", globData.translate("logout_performed"));
      });
    } else {
      globData.showPopup("login");
    }
  };

  const setLanguage = (e) => {
    globData.setLanguage(e.target.value);
  };

  return (
    <div className="login-dropdown" hover={visible + ""} onMouseEnter={() => setMODD(true)} onMouseLeave={() => setMODD(false)}>

      <p className="login-hello normal-text-color">{authorized ? translate("dropdown.hello").format(user.user.name.first, user.user.name.last) : translate("dropdown.hello_new")}</p>

      {authorized ? <>

        <button className='default-btn' onClick={() => navigate("/profile?t=o")}>{translate("dropdown.profile")}</button>

      </> : <></>}

      <div style={{ width: "100%", height: "20px" }} />

      <div className='dropdown-theme-main'>
        <div className='normal-text-color dd-theme-text'>{translate("dropdown.theme_toggle")}</div>
        <div className='dropdown-theme-toggle default-btn' style={{padding: 0}} onClick={() => globData.toggleTheme()}>
          <div className='theme-toggle-slider' />
        </div>
      </div>

      <div style={{ width: "100%", height: "12px" }} />

      <div className='dropdown-theme-main'>
        <div className='normal-text-color dd-theme-text'>{translate("dropdown.sel_language")}</div>
        <select className='normal-text-color dd-lang-sel' onChange={setLanguage} value={globData.language}>
          {Object.keys(globData.languages).map(key => (
            <option key={key} value={key}>{globData.languages[key]}</option>
          ))}
        </select>
      </div>

      <button className="login-dropdown-logout default-btn" onClick={logout}>{translate(authorized ? "dropdown.logout" : "login_title")}</button>
    </div>
  );
}
