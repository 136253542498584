import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import MainPage from './Pages/MainPage/MainPage';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Error from './Pages/Error/Error';

import axios from 'axios';
import Account from './Api/Account';
import Profile from './Pages/Profile/Profile';

function defaultProperties(state) {
  const create = (name, defVal) => state[name] = name in state ? state[name] : defVal;

  create("theme", "dark");
  create("lang", "ua");

  return state;
}

let load;
try { load = JSON.parse(localStorage.getItem("state")); } catch (err) { console.error(err); }
if (!load) load = {};
load = defaultProperties(load);

localStorage.setItem("env", localStorage.getItem("env") || 'production');

const root = ReactDOM.createRoot(document.getElementById('root'));

const profile = new Account();

async function waitForAll(futures) {
  let completed = {};

  for (let key in futures) {
    let result = await futures[key];
    completed[key] = result;
  }

  return completed;
}

if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    if (Array.isArray(args[0]) && args.length === 1) args = args[0];
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match
        ;
    });
  };
}

doRender();

function doRender() {
  if (localStorage.getItem("env") !== "dev") {
    root.render(<Error error="Site under construction..." />);
  } else renderMainPage();
}

function renderMainPage() {
  const globData = {};

  let langReq = axios.get("https://uapc.store/fixed/lang/" + (load.lang || "ua") + ".json");
  let langListReq = axios.get("https://uapc.store/fixed/lang/list.json");
  let ftBuldsReq = axios.get("https://uapc.store/fixed/featured_builds.json");
  let accReq = profile.fetchAccount();

  waitForAll({ lng: langReq, ftBuilds: ftBuldsReq, acc: accReq, langs: langListReq })
    .then((data) => {
      const { lng, ftBuilds, acc, langs } = data;

      globData.ftBuilds = ftBuilds.data;

      globData.classes = {
        Account: profile
      };

      globData.languages = langs.data;
      globData.language = load.lang;
      globData.langs = lng.data;
      globData.translate = (key, langs) => {
        let raw = lng.data[key] || key;
        return langs ? raw.format(langs) : raw;
      };

      var routes = [
        <Route
          key={"_0"}
          exact={true}
          path={""}
          element={
            <App savedState={load} langs={lng.data} globData={globData} user={acc}
              render={() => <MainPage globData={globData} />}
            />
          }
        />
      ];

      routes.push(<Route
        key="profile"
        path="profile"
        element={
          <App savedState={load} langs={lng.data} globData={globData} user={acc}
            render={() => <Profile globData={globData} />}
          />
        }
      />);

      for (var i = 0; i < globData.ftBuilds.length; ++i) {
        var build = globData.ftBuilds[i];

        var link = "prebuilds/" + build.id;

        routes.push(<Route
          key={link + "_" + i}
          exact={link === "/"}
          path={link}
          element={
            <App savedState={load} langs={lng.data} globData={globData} user={acc}
              render={() => <></>}
            />
          }
        />);
      }

      try {
        root.render(
          <React.StrictMode>
            <GoogleReCaptchaProvider
              reCaptchaKey='6LcwclUjAAAAABHkUnYiqjeFstDbGRgFxWfU9ibU'
              language={load.lang}
            >
              <Router>
                <Routes>
                  {routes}
                </Routes>
              </Router>
            </GoogleReCaptchaProvider>
          </React.StrictMode>
        );
      } catch (e) {
        console.error(e);
        root.render(<Error error={e.message} />);
      }
    })
    .catch(e => {
      console.error(e);
      root.render(<Error error={e.message} />);
    });
}