import React from 'react'

import CSS from './Error.css';

export default function Error({ error }) {
  return (
    <div className='error-body'>
      <div className='error-inner'>
        <h1 style={{ marginBottom: "8px", color: "#8b0000" }}>Ошибка!</h1>
        <h3 style={{ marginTop: "8px" }}>При загрузке страницы произошла ошибка:<br /><div style={{ marginTop: "12px" }}>{error}</div></h3>
        <p style={{ fontSize: "18px" }}>
          Попробуйте перезагрузить страницу.
          <br />
          Если это не решит проблему, обратитесь в поддержку в Telegram:
          <br />
          <a href="https://t.me/UAPC_Store" target="_blank" rel="noreferrer">
            <img src='https://uapc.store/fixed/png/telegram-app.png' height="18" style={{ "verticalAlign": "bottom", "marginRight": "4px" }} />
            {"@UAPC_Store"}
          </a>
        </p>
      </div>
    </div>
  )
}
