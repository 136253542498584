import BaseAPI from "./BaseAPI";

export default class ResetPassword extends BaseAPI {
    async cancelPasswordReset(captcha, token) {
        return await this.deleteJSON('change_password', { captcha, reset_token: token })
            .then(response => response.data);
    }

    async performPasswordReset(captcha, token, newPassword) {
        return await this.postJSON('change_password', { captcha, reset_token: token, new_password: newPassword })
            .then(response => response.data);
    }

    async requestPasswordReset(captcha, email) {
        return await this.postJSON('reset_password', { captcha, email })
            .then(response => response.data);
    }
} 