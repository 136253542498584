
export function createDropdown(color, text) {
  return {
    get: (key) => {
      if (key == "show_popup") return "true";
      if (key == "popup_color") return color;
      if (key == "popup_text") return text;
      return;
    }
  }
}