import React, { useRef, useState } from 'react'
import { decode as base64_decode, encode as base64_encode } from 'base-64';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './Register.css';

import axios from 'axios';

const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
const phoneRegex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
const passRegex = /(?=.*?([a-z]|[A-Z]))(?=.*?[0-9]).{8,}$/;

export default function Register({ globData }) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [canLogin, setCanLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const emailRef = useRef();
  const phoneRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const middleNameRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const loginBtnRef = useRef();

  function performLogin(e) {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.error("ReCaptcha is not ready yet!");
      return;
    }

    setCanLogin(false);
    setIsLoading(true);

    console.log("Performing register...");

    executeRecaptcha('perform_register_flow')
      .then((token) => {
        const json = {};

        json.email = emailRef.current.value;
        json.phone = phoneRef.current.value;
        json.password = base64_encode(passwordRef.current.value);
        json.captcha = token;
        json.first_name = firstNameRef.current.value;
        json.last_name = lastNameRef.current.value;
        if (middleNameRef.current.value && middleNameRef.current.value.length > 0) json.middle_name = middleNameRef.current.value;

        axios.post('https://uapc.store/api/register', JSON.stringify(json))
          .then((res) => {
            const data = res.data;
            if (data.ok) {
              globData.setState((state) => {
                let newState = { ...state };
                newState.user = data.data.user_id;
                newState.token = base64_decode(data.data.token);
                return newState;
              });
              globData.showPopup("");
              globData.createDropdown("#69B75B", globData.translate("register_performed"));
            } else {
              setCanLogin(true);
              setIsLoading(false);
              globData.createDropdown("#FF907F", globData.translate("register_account_already_exists"));
            }
          })
          .catch((e) => {
            globData.createDropdown("#FF907F", globData.translate("unexpected_error"));
          })
          .finally(() => {
            setCanLogin(true);
            setIsLoading(false);
            globData.classes.Account.reloadAccount();
          });
      })
      .catch((reason) => {
        console.error("Re-Captcha rejected with reason: " + reason);
        globData.createDropdown("#FF907F", globData.translate("error_captcha_failed_request"));
        setCanLogin(true);
        setIsLoading(false);
      });
  };

  function lockRegisterButton() {
    let loginValid = emailRegex.test(emailRef.current.value) && phoneRegex.test(phoneRef.current.value);
    let nameValid = firstNameRef.current.value?.length > 1 && lastNameRef.current.value?.length > 1;
    let passValid = passRegex.test(passwordRef.current.value) && passwordRef.current?.value === confirmPasswordRef.current?.value;
    setCanLogin(loginValid && nameValid && passValid);
  }

  return (
    <div className="register-popup">
      <section style={{ "justifyContent": "center", "display": "flex" }}>
        <div className="normal-text-color login-form-title">
          <span>{globData.translate("register_title")}</span>
        </div>
      </section>

      <div className='separator' />

      <div>
        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl">{globData.translate("register_email")}</span>
          <input onChange={lockRegisterButton} ref={emailRef} className="input-text-field" type={"email"} />
        </div>

        <div style={{ width: "100%", height: "20px" }} />

        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl">{globData.translate("register_phone")}</span>
          <input onChange={lockRegisterButton} ref={phoneRef} className="input-text-field" type={"tel"} />
        </div>

        <div className='separator' />

        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl">{globData.translate("register_first_name")}</span>
          <input onChange={lockRegisterButton} ref={firstNameRef} className="input-text-field" type={"first name"} />
        </div>

        <div style={{ width: "100%", height: "20px" }} />

        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl" >{globData.translate("register_last_name")}</span>
          <input onChange={lockRegisterButton} ref={lastNameRef} className="input-text-field" type={"last name"} />
        </div>

        <div style={{ width: "100%", height: "20px" }} />

        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl">{globData.translate("register_middle_name")}</span>
          <input onChange={lockRegisterButton} ref={middleNameRef} className="input-text-field" type={"middle name"} />
        </div>

        <div className='separator' />

        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl" >{globData.translate("register_password")}</span>
          <input onChange={lockRegisterButton} ref={passwordRef} className="input-text-field" type={"password"} />
        </div>

        <div style={{ width: "100%", height: "20px" }} />

        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl" >{globData.translate("register_confirm_password")}</span>
          <input onChange={lockRegisterButton} ref={confirmPasswordRef} className="input-text-field" type={"password"} />
        </div>
      </div>

      <div className='separator' />

      <section>
        <div className="login-buttons">
          <div className='switch-2-register-space'>
            <button className='switch-to-register default-btn' onClick={() => globData.showPopup("login")}>{globData.translate("register_btn_alt_login")}</button>
          </div>
          <div className='perform-login-space'>
            <button className='perform-login default-btn' loading={isLoading + ""} onClick={performLogin} disabled={!canLogin} ref={loginBtnRef}>{globData.translate("register_btn_submit")}</button>
          </div>
        </div>
      </section>
    </div>
  )
}