import axios from "axios"

const baseUri = '/api/';

export default class BaseAPI {
    constructor() {
        this.fields = {};
    }

    reset() {
        this.fields = {};
    }

    getJSON(uri) {
        return axios.get(baseUri + uri);
    }

    postJSON(uri, json) {
        return axios.post(baseUri + uri, JSON.stringify(json));
    }
    
    putJSON(uri, json) {
        return axios.put(baseUri + uri, JSON.stringify(json));
    }
    
    deleteJSON(uri, json) {
        return axios.delete(baseUri + uri, JSON.stringify(json));
    }
}