import React, { useRef, useState } from 'react'
import { decode as base64_decode, encode as base64_encode } from 'base-64';

import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import CSS from './Login.css';

import axios from 'axios';

const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;
const phoneRegex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
const passRegex = /(?=.*?([a-z]|[A-Z]))(?=.*?[0-9]).{8,}$/;

export default function Login({ globData }) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [canLogin, setCanLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginRef = useRef();
  const passRef = useRef();
  const loginBtnRef = useRef();

  function performLogin(e) {
    e.preventDefault();

    if (!executeRecaptcha) {
      console.error("ReCaptcha is not ready yet!");
      return;
    }

    setCanLogin(false);
    setIsLoading(true);

    console.log("Performing login...");

    executeRecaptcha('perform_login_flow')
      .then((token) => {
        const json = {};

        if (emailRegex.test(loginRef.current.value)) json.email = loginRef.current.value;
        if (phoneRegex.test(loginRef.current.value)) json.phone = loginRef.current.value;
        json.password = base64_encode(passRef.current.value);
        json.captcha = token;

        axios.post('https://uapc.store/api/login', JSON.stringify(json))
          .then((res) => {
            const data = res.data;
            if (data.ok) {
              globData.setState((state) => {
                let newState = { ...state };
                newState.user = data.data.user_id;
                newState.token = base64_decode(data.data.token);
                return newState;
              });
              globData.showPopup("");
              globData.createDropdown("#69B75B", globData.translate("login_performed"));
            } else {
              setCanLogin(true);
              setIsLoading(false);
              globData.createDropdown("#FF907F", globData.translate("login_invalid_credentials"));
            }
          })
          .catch((e) => {
            globData.createDropdown("#FF907F", globData.translate("unexpected_error"));
          })
          .finally(() => {
            setCanLogin(true);
            setIsLoading(false);
            globData.classes.Account.reloadAccount();
          });
      })
      .catch((reason) => {
        console.error("Re-Captcha rejected with reason: " + reason);
        globData.createDropdown("#FF907F", globData.translate("error_captcha_failed_request"));
        setCanLogin(true);
        setIsLoading(false);
      });
  };

  function lockLoginButton() {
    let login = loginRef.current.value;
    let loginValid = emailRegex.test(login) || phoneRegex.test(login);
    setCanLogin(loginValid && passRegex.test(passRef.current.value));
  }

  return (
    <div className="login-popup">
      <section style={{ "justifyContent": "center", "display": "flex" }}>
        <div className="normal-text-color login-form-title">
          <span>{globData.translate("login_title")}</span>
        </div>
      </section>

      <div className='separator' />

      <div>
        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl">{globData.translate("login_username")}</span>
          <input onChange={lockLoginButton} ref={loginRef} className="input-text-field" type={"email"} />
        </div>

        <div style={{ width: "100%", height: "20px" }} />

        <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
          <span className="normal-text-color vert-center-lbl">{globData.translate("login_password")}</span>
          <input onChange={lockLoginButton} ref={passRef} className="input-text-field" type={"password"} />
        </div>

        <div className='normal-text-color underlined-text'
          onClick={() => globData.showPopup("forgot_password")}
          style={{ "--brightness": "75%", textAlign: "right", margin: "12px 32px -10px 0", cursor: "pointer" }}>
          {globData.translate("login_forgot_pwd")}
        </div>
      </div>

      <div className='separator' />

      <section>
        <div className="login-buttons">
          <div className='switch-2-register-space'>
            <button className='switch-to-register default-btn' onClick={() => globData.showPopup("register")}>{globData.translate("login_btn_alt_register")}</button>
          </div>
          <div className='perform-login-space'>
            <button className='perform-login default-btn' loading={isLoading + ""} onClick={performLogin} disabled={!canLogin} ref={loginBtnRef}>{globData.translate("login_btn_submit")}</button>
          </div>
        </div>
      </section>
    </div>
  )
}