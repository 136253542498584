import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

import "./ForgotPassword.css";
import ChangePassword from "../../Api/ChangePassword";

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const emailRegex = /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/;

const PasswordAPI = new ChangePassword();

export default function ForgotPassword({ globData }) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [canLogin, setCanLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const emailRef = useRef();

    const lockButton = () => setCanLogin(emailRegex.test(emailRef.current.value));

    const closeReset = () => {
        globData.showPopup("login");
    };

    const performReset = () => {
        executeRecaptcha('forgot_password')
            .then((captcha) => {
                PasswordAPI.requestPasswordReset(captcha, emailRef.current.value)
                    .then((resp) => {
                        if(resp.ok) {
                            globData.createDropdown("#69B75B", globData.translate("forgot_email_sent", [ emailRef.current.value ]));
                            globData.closePopup();
                        }
                    })
                    .catch((e) => {
                      globData.createDropdown("#FF907F", globData.translate("unexpected_error"));
                    });
            })
            .catch((reason) => {
                console.error("Re-Captcha rejected with reason: " + reason);
                globData.createDropdown("#FF907F", globData.translate("error_captcha_failed_request"));
                setCanLogin(true);
                setIsLoading(false);
            });
    };

    return (
        <div className='reset-password-form normal-text-color'>
            <section style={{ "justifyContent": "center", "display": "flex" }}>
                <div className="normal-text-color reset-form-title">
                    <span>{globData.translate("reset_password_title")}</span>
                </div>
            </section>

            <div className='separator' />

            <div style={{ display: "flex", marginRight: "16px", justifyContent: "flex-end" }}>
                <span className="normal-text-color vert-center-lbl">{globData.translate("register_email")}</span>
                <input onChange={lockButton} ref={emailRef} className="input-text-field" type={"email"} />
            </div>

            <div className='separator' />

            <section>
                <div className="login-buttons">
                    <div className='switch-2-register-space'>
                        <button className='default-btn switch-to-register' onClick={closeReset}>{globData.translate("back")}</button>
                    </div>
                    <div className='perform-login-space'>
                        <button className='perform-login default-btn' loading={isLoading + ""} onClick={performReset} disabled={!canLogin}>{globData.translate("request_password_reset_btn")}</button>
                    </div>
                </div>
            </section>
        </div>
    )
}