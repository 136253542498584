import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import "./Profile.css";

function Overview({ globData }) {
    return <>
        Overview
    </>;
}

function Orders({ globData }) {
    return <>
        Orders
    </>;
}

function Reviews({ globData }) {
    return <>
        Reviews
    </>;
}

function Notifications({ globData }) {
    return <>
        Notificationshoie bhioeb hioebh ioeb ioebj pjerbp jtrboip jrtipob jriotb jriobt jrto bijrojb iop
    </>;
}

const pages = {
    "o": [Overview, "profile.tabs.overview"],
    "r": [Orders, "profile.tabs.orders"],
    "w": [Reviews, "profile.tabs.reviews"],
    "n": [Notifications, "profile.tabs.emails"]
};

export default function Profile({ globData }) {
    const { translate } = globData;
    const searchParams = new URLSearchParams(document.location.search);
    const navigate = useNavigate();
    const sel = searchParams.get("t") || 'o';

    return <div className='profile'>
        <div className="left-sections">
            <div className="left-profile-view">

            </div>
            <div className='all-sections'>
                {Object.keys(pages).map(pid =>
                    <div key={pid}>
                        <div className={"left-tab" + (sel === pid ? " left-tab-selected" : "")} onClick={() => navigate("?t=" + pid)}>{translate(pages[pid][1])}</div>
                        <div className='left-tab-separator' />
                    </div>
                )}
            </div>
        </div>

        <div className='right-section-entirety'>
            <div className='right-section normal-text-color'>
                {pages[sel][0]({ globData })}
            </div>
        </div>
    </div>;
}
